import { ProjectedPlayerSlatByDateConstants,  ProjectedPlayerSlatByDateLineUpConstants} from "../../constants";
import { projectedPlayerSlatByDateServices } from "../../services";

export const projectedPlayerSlatByDateActions = {
    getAllProjectedPlayerSlatByDate,
    getAllProjectedPlayerSlatByDateForLineUp
};

function getAllProjectedPlayerSlatByDate(date) {
    return dispatch => {
        dispatch(request());

        projectedPlayerSlatByDateServices.getAllProjectedPlayer(date)
            .then(
                projectedPlayerSlatByDateList => dispatch(success(projectedPlayerSlatByDateList)),
                error => dispatch(failure(error)) 
            );
    };

    function request() { return { type: ProjectedPlayerSlatByDateConstants.GETALLPROJECTEDPLAYERSLATBYDATE_REQUEST }; }
    function success(projectedPlayerSlatByDateList) { return { type: ProjectedPlayerSlatByDateConstants.GETALLPROJECTEDPLAYERSLATBYDATE_SUCCESS, projectedPlayerSlatByDateList }; }
    function failure(error) { return { type: ProjectedPlayerSlatByDateConstants.GETALLPROJECTEDPLAYERSLATBYDATE_FAILURE, error }; }
}


function getAllProjectedPlayerSlatByDateForLineUp() {
    return dispatch => {
        dispatch(request());
        // console.log(1232)
        projectedPlayerSlatByDateServices.getProjectedPlayerForLineUp()
            .then(
                projectedPlayerSlatByDateListLineUp => dispatch(success(projectedPlayerSlatByDateListLineUp)),
                error => dispatch(failure(error)) 
            );
    };

    function request() { return { type: ProjectedPlayerSlatByDateLineUpConstants.GETALLPROJECTEDPLAYERSLATBYDATELINEUP_REQUEST }; }
    function success(projectedPlayerSlatByDateListLineUp) { return { type: ProjectedPlayerSlatByDateLineUpConstants.GETALLPROJECTEDPLAYERSLATBYDATELINEUP_SUCCESS, projectedPlayerSlatByDateListLineUp }; }
    function failure(error) { return { type: ProjectedPlayerSlatByDateLineUpConstants.GETALLPROJECTEDPLAYERSLATBYDATELINEUP_FAILURE, error }; }
}